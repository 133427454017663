.archive-container {
    display: flex;
    flex-direction: column;

    font-size: .9rem;

    margin-left: 3rem;
    margin-right: 3rem;
}

.query-form-container {
    display: flex;
    flex-direction: row;

    margin-bottom: .8rem;
}

.query-container {
    display: flex;
    flex-direction: column;
    width: 80%;
}

.prompt-container {
    margin-top: 3.95rem;
    margin-left: .8rem;
    width: 20%;
}

.row-container {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.row-container .ReactTable {
    width: 100%;
}

.query-button {
    align-self: flex-end;
    margin-left: .3rem;
    width: 8rem;
}

.date-field {
    margin-left: .3rem;
}
.date-field:first-child {
    margin-left: 0;
}

.query-button {

}

.download-button {

}

.download-button-dis {
    background-color: dimgray;
}
