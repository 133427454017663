body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.deliveryLookupModal {
  /*position: absolute;
  z-index: 9999;*/
  max-width: 45vw;
  /*height: calc(100vh - 200px);*/
}

.deliveryLookupModal .modal-content {
  overflow-y: auto;
  max-width: 45vw;
}

input[type=checkbox] {
  -ms-transform: scale(1.5);
  -moz-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  -o-transform: scale(1.5);
  transform: scale(1.5);
  padding: 5px;
  cursor: pointer;
  width: auto;
  margin-left: 7px;
  margin-right: 7px;
}

input[type=checkbox]:hover 
input[type=checkbox]:active {
  box-shadow: 0 0 2pt 1pt #5cb3fd8a;
}