.cms {
    display: flex;
    flex-direction: column;

    width: 100%;

}

.cms .value-row {
    display: flex;
    flex-direction: row;

    margin-top: .2rem;
}

.cms .value-row:first-child {
    margin-top: 0rem;
}

.cms .value-container {
    margin-bottom: .5rem;
}

.cms input {
    font-size: .85rem;
}

.cms .remove {
    height: 2.3rem;
}
