.ffc {
    width: 100%;
}
.ffc-label {
    font-size: .85rem;
    margin: 0;
}
.ffc-row {
    display: flex;
    flex-direction: row;
}
.ffc-column {
    display: flex;
    flex-direction: column;
}
.ffc-error {
    color: #d9534f;
    margin-top: -2px;
    margin-bottom: -8px;
}
.ffc-warning {
    color: #ea8b23;
    margin-top: -2px;
    margin-bottom: -8px;
}
.ffc-input-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 0;
}

