.range-container {
    display: flex;
    flex-direction: row;

    margin: 0;
    padding: 0;
}

.range-field {
    display: flex;
    flex-direction: column;
    font-size: .85rem;

    margin-left: .3rem;
}

.range-field:first-child {
    margin-left: 0;
}
