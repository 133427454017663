.delivery-container {
    padding: .5rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.instructions {
    margin-bottom: .8rem;
}

.delivery-form {

}

.delivery-form Button {
    margin-left: .3rem;
}
.delivery-form Button:first-child {
    margin-left: 0rem;
}

.delivery-row {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
}

.filter {
    display: flex;
    flex-direction: row;
    margin-bottom: .3rem;
}

.date-received-field {
    width: 150%;
}

.undeliverable-field {
    margin-left: .3rem;
    flex-grow: 0;
}

.tracking-filter {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: .3rem;

}

.tracking-filter .form-control {
    height: auto;
}

.undeliverable-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-left: .3rem;
    margin-right: 1.3rem;

}

.fields-container > * {
    margin-top: .5rem
}

.delivery-field {
    flex-grow: 1;
    height: auto;

}

.unsaved-changes {
    text-align: center;
    display: block;
    margin-top: 1.75rem;
}

.button-bar {
    display: flex;
    flex-direction: row;

    width: 100%;

    margin-top: 3rem;
}

.divider {
    width: 100%;
    margin-top: 1.75rem;
    border-top: .0325rem solid lightGray
}

.child-container {
    width: 100%;
    margin-top: 1.75rem;
}

.recipient-search {
    width: 100%;
}

.recipient-row {
    display: flex;
    flex-direction: row;

    margin-top: .5rem;
};

.recipient-row:first-child {
    margin-top: 0;
}

.recipient-row-field {
    flex-grow: 1;

    margin-left: .5rem;
}
.recipient-row-field:first-child {
    margin-left: 0;
}
