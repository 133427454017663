.SignedInHeader {
    display: flex;
    flex-direction: row;
    overflow: auto;
    justify-content: flex-end;

    width: 100%;

    padding: .2rem .5rem .2rem 0;
}

.SignedInHeader Button {
    margin-left: .3rem;
}
