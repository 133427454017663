.filter-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    box-sizing: border-box;
}

.filter-container .rw-dropdown-list {
    width: 100%;
}

.fields-container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.field-container {
    display: flex;
    flex-direction: column;

    width: 100%;
}

.field-settings {
    display: flex;
    flex-direction: row;

    width: 100%;
}

.field-settings > * {
    flex-grow: 1;
    margin-left: .3rem;


}

.field-label {

}

.field-settings > *:first-child {
    margin-left: 0;
}

.add-container {
    display: flex;
    flex-direction: column;

    width: 100%;

    margin-top: 1rem;
    margin-bottom: .3rem;
}

.add-workflow {
    display: flex;
    flex-direction: row;

    width: 100%;
}

.add-btn {
    margin-left: .3rem;
}

.btn-bar {
    display: flex;
    flex-direction: row;
    justify-content: right;

    width: 100%;
}

.query-btn {
    margin-right: .3rem;
}

.filters-title {
    margin: auto;
    margin-left: 0;
    margin-bottom: 0;
    font-size: 1rem;
}
